body {
  margin: 0;
  font-family: "Roboto Thin", monospace;
  color: white;
  background-color: #151515;
}

.content-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h1, p {
  animation: fadeIn 1s ease-in forwards;
}

p {
  animation-delay: 0.5s;
}

h1, p {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

p {
  animation-delay: 0.5s;
}

#section1 {
  background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.25),
          rgba(21, 21, 21, 1)
  ),
  url('background2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.code-box {
  background-color: rgba(8, 8, 8, 1);
  color: #ffffff;
  border: 0px solid #444444;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  max-width: 80%;
  margin-top: 10px;
}

.title-bar {
  background-color: #181818;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.title {
  font-family: 'Courier New', Courier, monospace;
  font-weight: normal;
  color: #2f2f2f;
}

.buttons {
  display: flex;
}

.button {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.red {
  background-color: #ff5c5c;
}

.yellow {
  background-color: #ffcc5c;
}

.green {
  background-color: #5cff5c;
}

pre {
  padding: 15px;
  margin: 0;
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
}

#section2 {
  background-color: #151515;
  height: 175vh;
}

#section3 {
  background-color: #181818;
  height: 10vh;
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  flex-direction: column;
}

h1 {
  font-family: 'Roboto Thin', monospace;
  font-size: 5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

h2 {
  font-family: 'Roboto Thin', monospace;
  font-size: 2rem;
  color: #2b2e2b;
  margin: 20px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  gap: 25px;
}

.social-icons img {
  width: 30px;
  height: 30px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  filter: grayscale(100%);
  cursor: pointer;
}

.social-icons img:hover {
  filter: grayscale(0);
  opacity: 1;
}

@keyframes fadeInIcons {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.social-icons img {
  animation: fadeInIcons 2s ease-in-out forwards;
}


