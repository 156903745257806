.project {
    background-color: rgba(20, 20, 20, 0.8);
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    text-align: left;
    max-width: 800px;
    min-height: 300px;
}

.skills {
    margin-top: 10px;
}

.skill-icon {
    display: inline-block;
    padding: 10px;
    border-radius: 15%;
    background-color: rgba(255, 255, 255, 0.025);
    margin-right: 10px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
}


.skill-image {
    width: 30px;
    height: auto;
}

.project-description {
    word-wrap: break-word;
    white-space: normal;
    max-height: none;
    overflow-wrap: break-word;
    margin-bottom: 15px;
}

.github-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #00bfff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
}

.github-button:hover {
    background-color: #0099cc;
}

.project-title {
    font-family: 'Roboto Light', monospace;
    font-size: 1.8rem;
    font-weight: bold;
}

.project-description {
    font-family: 'Roboto Thin', monospace;
    color: #535755;
    font-size: 1rem;
}
